@import './reboot';
@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

[v-cloak] {
    visibility: hidden;
    opacity: 0;
  }

html {
    font-size: 62.5%;
    height: 100%;
}

.body {
    font-family: $default-font-family;
    background: map-get($colors, background);
    font-size: $default-font-size;
    font-weight: $font-weight-regular;
    color: map-get($colors, text);

}

.logo {
  max-width: 200px;
  width: 100%;
  margin-bottom: map-get($spaces, margin);
}

.app {
    padding: map-get($spaces, padding);
    height: 100%;

    &--login {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &--100vh {
        height: 100vh;
    }
}

h1, h2, h3, .head {
    font-family: 'Roboto', sans-serif;
    color: map-get($colors, text);
    font-weight: $font-weight-medium;
    margin-bottom: map-get($spaces, margin);
}

h1, .head1 {
    font-size: 25px;
}

h2, .head2 {
    font-size: 21px;
}

h3, .head3 {
    font-size: 18px;
}

ul {
    padding-left: map-get($spaces, margin);
    margin-bottom: map-get($spaces, margin);
    }

li {
    color: map-get($colors, text-subtle);
}

p {
    color: map-get($colors, text-subtle);
    font-size: $default-font-size;
    margin-top: 0;
    margin-bottom: map-get($spaces, margin);
}

a {
    color: $link;
    font-weight: $font-weight-regular;

    &:hover {
      text-decoration: none;
    }
  }

.container {
    width: 100%;
    margin: 0 auto;

    &--sm {
        max-width: 350px;
    }

    &--md {
        max-width: 768px;
    }

    &--centered {
        text-align: center;
    }
}
.button {
  border: 0;
}

.button--primary {
  color: map-get($colors, white);
  background: get-color(primary);
  padding: 15px 20px;
  border-radius: $btn-radius;
  display: flex;
  align-items: center;
  transition: .2s all;
  justify-content: center;
  cursor: pointer;

  &--has-margin {
    margin-bottom: 24px;
  }

  &__icon {
    margin-right: 12px;
  }

  &:hover {
    transform: scale(0.9);
    box-shadow: $btn-box-shadow;
  }
}
