@use "sass:map";



$colors: (
  white: #fff,
  primary: #93D109,
  background: #FDFDFD,
  text: #565B77,
  text-subtle: #787b8e,
  danger: #f44336,
);

@function get-color($name) {
    @return var(--#{$name}, map.get($colors, $name));
}

$spaces: (
  margin: 24px,
  padding: 24px,
);

$default-radius: 15px;
$shadow: 0 5px 10px rgba(8,22,80, 0.08);

// Fonts
$default-font-family: 'Roboto', sans-serif;
$default-font-size: 1.6rem;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Anchors
$link: get-color(primary);

// Card
$bg-card: map-get($colors, white);

// Button
$btn-radius: $default-radius;
$btn-box-shadow: $shadow;

